import PropTypes from 'prop-types';
import React, { useState } from 'react';
import style from './TaskList.module.css';

const TaskList = (props) => {
  const [records, setRecords] = useState(window.initalRecords)

  var stringToColour = function(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }

  return (
    <div className="mt-4">
      {records.map((record) => 
        <div style={{backgroundColor: stringToColour(record.publication) }} className={[style.taskCard + ' flex-wrap d-flex mb-2 p-3']}>
          <div className="flex-row align-items-start flex-nowrap justify-content-around">
            <div className={[style.publication + ' order-1']}>{record.publication}</div>
            <div className={[style.publishedAt + ' order-5']}>{record.published_at}</div>
            <div className={[style.page + ' order-3']}>page {record.page}</div>
          </div>
          <hr />
          <div className="flex-row">
            <div className={record.stared ? 'stared': null}>
              <div className={style.title}>{record.title}</div>
            </div>
          </div>
          <div className="flex-row">
            {record.subjects.map((subject) => 
              <span className={style.subject}>{subject.name}</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};


export default TaskList;
