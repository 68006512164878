import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import style from './EditField.module.css';

const EditField = (props) => {
  const [id, setId] = useState(props.id);
  const [publishedAt, setPublishedAt] = useState(props.published_at);
  const [title, setTitle] = useState(props.title);
  const [isEditing, setIsEditing] = useState(false);

  const validate = values => {
    let errors = {};
    if (!values.title) {
      errors.title = 'Required';
    }
    console.log(errors)
    return errors;
  }
  const toggleEditor = () => {
    setIsEditing(!isEditing);
  }
  async function handleSubmit(values) {
    const token =  document.querySelector('[name=csrf-token]').content

    fetch(`/records/${id}.json`, {
    method: 'PUT',
      body: JSON.stringify({ ...values }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': token
      },
    })
    .then(response => response.json())
    .then(data => {
      console.log('data: ', data)
      // Handle response data if needed
    })
    .catch(error => {
      // Handle error if needed
    });
  }
  return (
    <div id={id}>
      <img onClick={toggleEditor} src="https://icongr.am/octicons/pencil.svg?size=30&color=currentColor"></img>
      <Formik
        initialValues={{
          title: title,
          publishedAt: publishedAt,
        }}
        validate={validate}
        onSubmit={async (values) => {
          await handleSubmit(values)
        }}
      >
      {({ isSubmitting, errors }) => (
        <Form className={isEditing ? null: 'd-none'}>
          <div className="form-group col-md-6">
            <label htmlFor="title">Title</label>
            <Field className={`form-control form-control-lg ${errors.title ? 'is-invalid' : null}`} name="title" />
            <ErrorMessage name="title" component="div" className="invalid-feedback" />
          </div>
          <div className="form-group col-md-2">
            <label htmlFor="publishedAt">Published At</label>
            <Field className="form-control form-control-sm" name="publishedAt" />
            <button type="submit" className="btn btn-primary mb-2" disabled={isSubmitting}>
              Submit
            </button>
          </div>

        </Form>
      )}
    </Formik>
    </div>

  );
};

EditField.propTypes = {
  id: PropTypes.string.isRequired, // this is passed from the Rails view
};

export default EditField;
