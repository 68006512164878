import ReactOnRails from 'react-on-rails';

import HelloWorld from '../bundles/HelloWorld/components/HelloWorld';
import Star from '../bundles/HelloWorld/components/Star';
import Plus from '../bundles/HelloWorld/components/Plus';
import EditField from '../bundles/HelloWorld/components/EditField';
import TaskList from '../bundles/HelloWorld/components/TaskList';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  HelloWorld,
  Star, Plus,
  EditField, TaskList
});
