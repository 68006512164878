import PropTypes from 'prop-types';
import React, { useState } from 'react';
import style from './HelloWorld.module.css';

function Header({name}){
  if(name == 'evan'){
    return <h3><u>Evan 😀</u></h3>;
  } else {
    return <h3>{name}</h3>;
  }

}
const HelloWorld = (props) => {
  const [name, setName] = useState(props.name);

  return (
    <div>
      <Header name={name}></Header>
      <hr />
      <form>
        <label className={style.bright} htmlFor="name">
          Say hello to:
          <input id="name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
        </label>
      </form>
    </div>
  );
};

HelloWorld.propTypes = {
  name: PropTypes.string.isRequired, // this is passed from the Rails view
};

export default HelloWorld;
