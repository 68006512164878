import PropTypes from 'prop-types';
import React, { useState } from 'react';
import style from './Star.module.css';

const Plus = (props) => {
  const [id, setId] = useState(props.id);
  const [activated, setActivated] = useState(props.tasked);
  


  function handleClick() {
    console.log('here')
    const token =  document.querySelector('[name=csrf-token]').content
    
    // Make HTTP request here
    // You can use the built-in fetch API or a library like Axios to make the request
    // Example using fetch:
    fetch(`/tasks/`, {
      method: 'POST',
      body: JSON.stringify({ id }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': token
      },
    })
    .then(response => response.json())
    .then(data => {
      console.log(data)
      setActivated(!activated)
      // Handle response data if needed
    })
    .catch(error => {
      // Handle error if needed
    });
  }
  return (
    <div id={id} onClick={handleClick} style={{display : 'inline-block'}}>
      <img
          src={`https://icongr.am/octicons/diff-${activated ? 'removed' : 'added'}.svg?size=30&color=currentColor`}></img>
    </div>
  );
};

Plus.propTypes = {
  id: PropTypes.string.isRequired, // this is passed from the Rails view
};

export default Plus;
