import PropTypes from 'prop-types';
import React, { useState } from 'react';
import style from './Star.module.css';

const Star = (props) => {
  const [id, setId] = useState(props.id);
  const [activated, setActivated] = useState(props.stared);

  function handleClick() {
    const token =  document.querySelector('[name=csrf-token]').content

    fetch(`/stars/`, {
      method: 'POST',
      body: JSON.stringify({ id }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': token
      },
    })
    .then(response => response.json())
    .then(data => {
      console.log(data)
      setActivated(!activated)
    })
    .catch(error => {

    });
  }
  return (
    <div id={id} onClick={handleClick} style={{display : 'inline-block'}} >
      <img className={activated ? style.activated : '' } src="https://icongr.am/octicons/star.svg?size=30&color=currentColor"></img>
    </div>
  );
};

Star.propTypes = {
  id: PropTypes.string.isRequired, // this is passed from the Rails view
};

export default Star;
